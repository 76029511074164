import React from "react";
import "./MyProfile.scss";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/slices/authSlice";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import MyJobs from "../../components/MyJobs/MyJobs";
import Follow from "../../components/Follow/Follow";
import { FcGlobe } from "react-icons/fc";

const MyProfile = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  const baseUrl = process.env.REACT_APP_BASE_URL;

  const { t } = useTranslation("pages");

  const defaultCoverPicture = `${baseUrl}/uploads/cover.png`;
  const defaultProfilePicture = `${baseUrl}/uploads/profile.png`;

  const handleLogout = () => {
    dispatch(logout());
  };

  const coverPictureUrl =
    user?.coverPicture && user.coverPicture !== "null"
      ? `${baseUrl}/uploads/${user.coverPicture}`
      : defaultCoverPicture;

  const profilePictureUrl =
    user?.profilePicture && user.profilePicture !== "null"
      ? `${baseUrl}/uploads/${user.profilePicture}`
      : defaultProfilePicture;

  console.log("User data:", user);
  console.log("Cover Picture URL:", coverPictureUrl);
  console.log("Profile Picture URL:", profilePictureUrl);

  return (
    <div className="profile-container">
      <div className="cover-picture">
        <img
          src={coverPictureUrl}
          alt={t("profile.coverPictureAlt")}
          className="cover-img"
        />
      </div>
      <div className="profile-card">
        <div className="profile-picture">
          <img
            src={profilePictureUrl}
            alt={t("profile.profilePictureAlt")}
            className="profile-img"
          />
        </div>
        <h2>{user?.fullName}</h2>
        <h3>User ID: {user?.userId}</h3>
        <p className="email">{user?.email}</p>
        <p className="country">
          <FcGlobe /> {user?.country}
        </p>
        <Follow />
        <div className="profile-details">
          <div className="profile-field">
            <span className="label">{t("profile.accountCreatedLabel")}</span>
            <span className="value">
              {user?.createdAt
                ? new Date(user.createdAt).toLocaleDateString()
                : ""}
            </span>
          </div>
        </div>
        <div className="action-buttons">
          <Link to="/my-profile/update-profile" className="nbutton edit-button">
            {t("profile.editProfileButton")}
          </Link>
          <button className="nbutton logout-button" onClick={handleLogout}>
            {t("profile.logoutButton")}
          </button>
        </div>
      </div>
      <h2>
        <MyJobs />
      </h2>
    </div>
  );
};

export default MyProfile;
