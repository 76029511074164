import React from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/slices/authSlice";
import { useTranslation } from "react-i18next";
import "./Navbar.scss";
import logo from "../../assets/logonew.png";
import SearchUserBar from "../SearchUserBar/SearchUserBar";

const Navbar = () => {
  const dispatch = useDispatch();
  const { isAuthenticated, user } = useSelector((state) => state.auth);

  // Doğru namespace: "components"
  const { t } = useTranslation("components");

  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <nav className="navbar">
      <div className="navbar__logo">
        <Link to="/enterance">
          <img src={logo} alt="Logo" />
        </Link>
      </div>
      {isAuthenticated && (
        <div className="navbar__buttons">
          <span className="navbar__welcome">
            {t("navbar.welcome")} {user.fullName}
          </span>
          <Link to="/enterance" className="navbar__button">
            {t("navbar.buttons.startNow")}
          </Link>
          <Link to="/my-profile" className="navbar__button">
            {t("navbar.buttons.profile")}
          </Link>
          <Link to="/support" className="navbar__button">
            {t("navbar.buttons.support")}
          </Link>
          <button onClick={handleLogout} className="navbar__button logout">
            {t("navbar.buttons.logout")}
          </button>
        </div>
      )}
      <SearchUserBar />
    </nav>
  );
};

export default Navbar;
