import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { FaRegTrashAlt } from "react-icons/fa";
import "./MyJobs.scss";

const MyJobs = () => {
  const { t } = useTranslation("components");
  const { user, isAuthenticated } = useSelector((state) => state.auth);
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (isAuthenticated && user?.userId) {
      const fetchJobs = async () => {
        setLoading(true);
        setError(null);

        try {
          const response = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/job/getUserJobs`,
            { userId: user.userId },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          );

          if (response.data?.success && response.data?.result) {
            setJobs(response.data.result);
          } else {
            setJobs([]);
          }
        } catch (err) {
          setError(err.response?.data?.message || t("myJobs.errorMessage", { error: "Failed to fetch jobs." }));
        } finally {
          setLoading(false);
        }
      };

      fetchJobs();
    }
  }, [user, isAuthenticated, t]);

  const handleDeleteJob = async (jobId) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/job/delete`,
        { jobId },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.data?.success) {
        setJobs((prevJobs) => prevJobs.filter((job) => job.jobId !== jobId));
      } else {
        setError(t("myJobs.errorMessage", { error: "Failed to delete job." }));
      }
    } catch (err) {
      setError(err.response?.data?.message || t("myJobs.errorMessage", { error: "Failed to delete job." }));
    }
  };

  if (!isAuthenticated) return <p>{t("myJobs.loginPrompt")}</p>;
  if (loading) return <p>{t("myJobs.loadingMessage")}</p>;
  if (error) return <p>{t("myJobs.errorMessage", { error })}</p>;

  return (
    <div className="my-jobs">
      <h1>{t("myJobs.title")}</h1>
      {jobs.length > 0 ? (
        <ul className="card-container">
          {jobs.map((job) => (
            <li key={job._id} className="job-card">
              <h2>{job.jobService}</h2>
              <p><strong>{t("myJobs.category")}</strong> {job.jobCategory}</p>
              <p><strong>{t("myJobs.location")}</strong> {job.city}, {job.district}</p>
              <p><strong>{t("myJobs.description")}</strong> {job.description}</p>
              <p><strong>{t("myJobs.hourlyWage")}</strong> ${job.hourlyWage}</p>
              <small>{t("myJobs.postedOn")}: {new Date(job.createdAt).toLocaleDateString()}</small>
              <p className="trash" onClick={() => handleDeleteJob(job.jobId)}><FaRegTrashAlt /></p>
            </li>
          ))}
        </ul>
      ) : (
        <p>{t("myJobs.noJobsMessage")}</p>
      )}
    </div>
  );
};

export default MyJobs;
