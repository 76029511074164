import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import "./UserProfile.scss";
import Follow from "../../components/Follow/Follow";

const UserProfile = () => {
  const { userId } = useParams();
  const [user, setUser] = useState(null);
  const baseUrl = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await axios.post(`${baseUrl}/user/profile`, { userId });
        if (response.data.success) {
          setUser(response.data.user);
        }
      } catch (error) {
        console.error("Error fetching user profile:", error);
      }
    };

    fetchUser();
  }, [userId]);

  if (!user) {
    return <div>Loading...</div>;
  }

  const defaultCoverPicture = `${baseUrl}/uploads/cover.png`;
  const defaultProfilePicture = `${baseUrl}/uploads/profile.png`;

  const coverPictureUrl = user.coverPicture
    ? `${baseUrl}/uploads/${user.coverPicture}`
    : defaultCoverPicture;
  const profilePictureUrl = user.profilePicture
    ? `${baseUrl}/uploads/${user.profilePicture}`
    : defaultProfilePicture;

  return (
    <div className="user-profile">
      <div className="cover-picture">
        <img src={coverPictureUrl} alt="Cover" className="cover-img" />
      </div>
      <div className="profile-info">
        <img
          src={profilePictureUrl}
          alt={user.fullName}
          className="profile-picture"
        />
        <h2>{user.fullName}</h2>
        <p>Email: {user.email}</p>
        <p>kaldırılacak user id : {user.userId}</p>
        <p>Country: {user.country}</p>
        <p>Joined: {new Date(user.createdAt).toLocaleDateString()}</p>
      </div>
      <Follow userId={user.userId}/>
    </div>
  );
};

export default UserProfile;
