import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Support.scss";
import axios from "axios";

const Support = () => {
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!email.trim() || !subject.trim()) {
      setMessage("Lütfen tüm alanları doldurunuz.");
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/support/general`,
        {
          email,
          subject,
        }
      );
      setMessage(response.data.message);
      setTimeout(() => navigate("/"), 2000); // Redirect to homepage after 2 seconds
      setEmail("");
      setSubject("");
    } catch (error) {
      setMessage("Bir hata oluştu. Lütfen tekrar deneyiniz.");
    }
  };

  return (
    <div className="support-container">
      <h1>Dilek, İstek ve Öneriler</h1>
      <form onSubmit={handleSubmit} className="support-form">
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Lütfen email adresinizi giriniz..."
          className="support-input"
        />
        <textarea
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
          placeholder="Lütfen önerinizi yazınız..."
          className="support-textarea"
        />
        <button type="submit" className="support-submit-button">
          Gönder
        </button>
      </form>
      {message && <p className="support-message">{message}</p>}
    </div>
  );
};

export default Support;