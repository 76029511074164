import React, { useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import "./SearchUserBar.scss";

const SearchUserBar = () => {
  const [query, setQuery] = useState("");
  const [results, setResults] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);

  const baseUrl = process.env.REACT_APP_BASE_URL;
  const defaultProfilePicture = `${baseUrl}/uploads/profile.png`;

  const handleSearch = async (e) => {
    const value = e.target.value;
    setQuery(value);

    if (value.trim() === "") {
      setResults([]);
      setShowDropdown(false);
      return;
    }

    try {
      const response = await axios.post(
        `${baseUrl}/search/user`,
        { query: value },
        { headers: { "Content-Type": "application/json" } }
      );
      if (response.data.success) {
        setResults(response.data.result);
        setShowDropdown(true);
      }
    } catch (error) {
      console.error("Error fetching search results:", error.response || error);
    }
  };

  const handleBlur = () => {
    setTimeout(() => setShowDropdown(false), 200);
  };

  return (
    <div className="search-user-bar">
      <input
        type="text"
        placeholder="Search for users..."
        value={query}
        onChange={handleSearch}
        onBlur={handleBlur}
      />
      {showDropdown && results.length > 0 && (
        <ul className="dropdown">
          {results.map((user) => {
            const profilePictureUrl = user.profilePicture
              ? `${baseUrl}/uploads/${user.profilePicture}`
              : defaultProfilePicture;

            return (
              <li key={user.userId}>
                <Link to={`/user/profile/${user.userId}`}>
                  <img src={profilePictureUrl}/>
                  <span>{user.fullName}</span>
                </Link>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

export default SearchUserBar;
