import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { BiSupport } from "react-icons/bi";
import "./Home.scss";

const Home = () => {
  const { t } = useTranslation("pages");

  return (
    <div className="main-container">
      <div className="first-temp">
        <div className="support">
          <Link to="support">{t("home.buttons.support")}<BiSupport/></Link>
        </div>
        <div className="buttons">
          <Link to="/login">
            <button>{t("home.buttons.login")}</button>
          </Link>
          <Link to="/register">
            <button>{t("home.buttons.register")}</button>
          </Link>
        </div>
      </div>
      <div className="purpose">
        <h1>{t("home.purpose.title")}</h1>
        <p>{t("home.purpose.text")}</p>
      </div>
    </div>
  );
};

export default Home;
