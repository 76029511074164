import React, { useState } from "react";
import "./UpdateProfile.scss";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../redux/slices/authSlice";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Alert from "../../components/Alert/Alert";

const UpdateProfile = () => {
  const { user } = useSelector((state) => state.auth);
  const [fullName, setFullName] = useState(user.fullName);
  const [email, setEmail] = useState(user.email);
  const [country, setCountry] = useState(user.country);
  const [profilePicture, setProfilePicture] = useState(null);
  const [coverPicture, setCoverPicture] = useState(null);
  const [alert, setAlert] = useState({ show: false, type: "", message: "" });

  const { t } = useTranslation("pages");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const baseUrl = process.env.REACT_APP_BASE_URL;

  const showAlert = (type, message) => {
    setAlert({ show: true, type, message });
    setTimeout(() => setAlert({ show: false, type: "", message: "" }), 3000);
  };

  const handleImageUpload = async (type, imageFile) => {
    const reader = new FileReader();

    reader.onloadend = async () => {
      const base64Image = reader.result.split(",")[1]; // Base64 kodunu alıyoruz
      const jsonData = {
        userId: user.userId,
        image: base64Image,
      };

      try {
        const response = await axios.post(`${baseUrl}/photo/${type}`, jsonData, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        showAlert("success", response.data.message);
      } catch (error) {
        console.error("Error uploading image:", error.response?.data || error.message);
        showAlert("error", t("updateProfile.uploadError"));
      }
    };

    reader.readAsDataURL(imageFile); // Dosyayı base64 formatına çeviriyoruz
  };

  const handleProfileUpdate = async () => {
    try {
      const response = await axios.post(`${baseUrl}/user/updateProfile`, {
        userId: user.userId,
        fullName,
        email,
      });
      showAlert("success", response.data.message);
    } catch (error) {
      console.error("Error updating profile:", error.response?.data || error.message);
      showAlert("error", t("updateProfile.updateError"));
    }
  };

  const handleCountryChange = async () => {
    try {
      const response = await axios.post(`${baseUrl}/user/changeCountry`, {
        userId: user.userId,
        country,
      });
      showAlert("success", response.data.message);
    } catch (error) {
      console.error("Error changing country:", error.response?.data || error.message);
      showAlert("error", t("updateProfile.countryError"));
    }
  };

  const handleAccountRemoval = async () => {
    if (window.confirm(t("updateProfile.confirmDelete"))) {
      try {
        const response = await axios.post(`${baseUrl}/user/removeAccount`, {
          userId: user.userId,
        });
        showAlert("success", response.data.message);
        dispatch(logout());
        navigate("/login");
      } catch (error) {
        console.error("Error removing account:", error.response?.data || error.message);
        showAlert("error", t("updateProfile.deleteError"));
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (profilePicture) {
      await handleImageUpload("profilePicture", profilePicture);
    }
    if (coverPicture) {
      await handleImageUpload("coverPicture", coverPicture);
    }
    await handleProfileUpdate();
    await handleCountryChange();
  };

  return (
    <div className="update-profile-container">
      <h1>{t("updateProfile.title")}</h1>
      {alert.show && <Alert type={alert.type} message={alert.message} />}
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>{t("updateProfile.fullNameLabel")}</label>
          <input
            type="text"
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>{t("updateProfile.emailLabel")}</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>{t("updateProfile.countryLabel")}</label>
          <select
            value={country}
            onChange={(e) => setCountry(e.target.value)}
          >
            <option value="cn">{t("register.options.cn")}</option>
            <option value="de">{t("register.options.de")}</option>
            <option value="fr">{t("register.options.fr")}</option>
            <option value="in">{t("register.options.in")}</option>
            <option value="jp">{t("register.options.jp")}</option>
            <option value="kr">{t("register.options.kr")}</option>
            <option value="ru">{t("register.options.ru")}</option>
            <option value="tr">{t("register.options.tr")}</option>
            <option value="uk">{t("register.options.uk")}</option>
            <option value="us">{t("register.options.us")}</option>
          </select>
        </div>
        <div className="form-group">
          <label>{t("updateProfile.profilePictureLabel")}</label>
          <input
            type="file"
            onChange={(e) => setProfilePicture(e.target.files[0])}
          />
        </div>
        <div className="form-group">
          <label>{t("updateProfile.coverPictureLabel")}</label>
          <input
            type="file"
            onChange={(e) => setCoverPicture(e.target.files[0])}
          />
        </div>
        <div className="form-actions">
          <button type="submit" className="nbutton save-button">
            {t("updateProfile.saveButton")}
          </button>
          <button
            type="button"
            className="nbutton delete-button"
            onClick={handleAccountRemoval}
          >
            {t("updateProfile.deleteAccountButton")}
          </button>
        </div>
      </form>
    </div>
  );
};

export default UpdateProfile;
