import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Follow.scss";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Follow = ({ userId: propUserId }) => {
  const [countData, setCountData] = useState({
    followingCount: 0,
    followersCount: 0,
  });
  const [listData, setListData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [isFollowing, setIsFollowing] = useState(false);

  const { user } = useSelector((state) => state.auth);
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const userId = propUserId || user?.userId;
  const { t } = useTranslation("components");

  const fetchCounts = async () => {
    try {
      const response = await axios.post(`${baseUrl}/follow/count`, { userId });
      if (response.data.success) {
        setCountData({
          followingCount: response.data.followingCount,
          followersCount: response.data.followersCount,
        });
      }
    } catch (error) {
      console.error("Error fetching count data:", error);
    }
  };

  const fetchFollowStatus = async () => {
    if (!propUserId || !user?.userId) return;
  
    try {
      // Takip edilenler listesini al
      const response = await axios.post(`${baseUrl}/follow/following`, {
        userId: user.userId,
      });
  
      if (response.data.success) {
        // Eğer propUserId takip edilenler listesinde varsa takip ediliyor
        const isUserFollowing = response.data.result.some(
          (followedUser) => followedUser.userId === propUserId
        );
        setIsFollowing(isUserFollowing);
      }
    } catch (error) {
      console.error("Error fetching follow status:", error);
    }
  };
  

  const fetchList = async (endpoint, title) => {
    try {
      const response = await axios.post(`${baseUrl}/follow/${endpoint}`, { userId });
      if (response.data.success) {
        setListData(response.data.result);
        setModalTitle(title);
        setIsModalOpen(true);
      }
    } catch (error) {
      console.error(`Error fetching ${title.toLowerCase()} data:`, error);
    }
  };

  const handleFollowToggle = async () => {
    try {
      const response = await axios.post(`${baseUrl}/follow`, {
        userId: user?.userId,
        followUserId: propUserId,
        follow: !isFollowing,
      });
      if (response.data.success) {
        setIsFollowing(!isFollowing);
        setCountData((prev) => ({
          ...prev,
          followersCount: prev.followersCount + (isFollowing ? -1 : 1),
        }));
      }
    } catch (error) {
      console.error("Error toggling follow status:", error);
    }
  };

  useEffect(() => {
    if (userId) fetchCounts();
    if (propUserId && user?.userId) {
      fetchFollowStatus();
    }
  }, [userId, propUserId, user?.userId]);;

  return (
    <div className="follow-container">
      <div className="follow-item" onClick={() => fetchList("followers", t("follow.followers"))}>
        <span className="follow-count">{countData.followersCount}</span>
        <span className="follow-label">{t("follow.followers")}</span>
      </div>
      <div className="follow-item" onClick={() => fetchList("following", t("follow.followings"))}>
        <span className="follow-count">{countData.followingCount}</span>
        <span className="follow-label">{t("follow.followings")}</span>
      </div>

      {propUserId && propUserId !== user?.userId && (
        <button
          className={`follow-button ${isFollowing ? "unfollow" : "follow"}`}
          onClick={handleFollowToggle}
        >
          {isFollowing ? t("follow.unfollow") : t("follow.follow")}
        </button>
      )}

      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <h3>{modalTitle}</h3>
            <button className="modal-close" onClick={() => setIsModalOpen(false)}>
              &times;
            </button>
            {listData.length > 0 ? (
              <ul className="list">
                {listData.map((user) => (
                  <li key={user._id} className="list-item">
                    <Link to={`/user/profile/${user.userId}`}>
                      <img
                        src={`${baseUrl}/uploads/${user.profilePicture}`}
                        alt={user.fullName}
                        className="profile-picture"
                      />
                      <span className="full-name">{user.fullName}</span>
                    </Link>
                  </li>
                ))}
              </ul>
            ) : (
              <p className="no-user">{t("follow.noUsers")}</p>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Follow;
