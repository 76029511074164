import { Routes, Route } from "react-router-dom";
import ScrollToTop from "../components/ScrollToTop";
import Home from "../pages/Home/Home";
import Login from "../pages/Login/Login";
import Register from "../pages/Register/Register";
import PrivateRoute from "../routers/PrivateRoute";
import Enterance from "../pages/Enterance/Enterance";
import FindJob from "../pages/FindJob/FindJob";
import PostJob from "../pages/PostJob/PostJob";
import UserProfile from "../pages/UserProfile/UserProfile";
import UpdateProfile from "../pages/UpdateProfile/UpdateProfile";
import MyProfile from "../pages/MyProfile/MyProfile";
import Support from "../pages/Support/Support";

function Routers() {
  return (
    <>

      <ScrollToTop />


      <Routes>

        <Route path="/" element={<Home />} />


        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />


        <Route
          path="/enterance"
          element={
            <PrivateRoute>
              <Enterance />
            </PrivateRoute>
          }
        />
        <Route
          path="/find-job"
          element={
            <PrivateRoute>
              <FindJob />
            </PrivateRoute>
          }
        />
        <Route
          path="/my-profile"
          element={
            <PrivateRoute>
              <MyProfile/>
            </PrivateRoute>
          }
        />
        <Route
          path="/my-profile/update-profile"
          element={
            <PrivateRoute>
              <UpdateProfile />
            </PrivateRoute>
          }
        />
        <Route
          path="/post-job"
          element={
            <PrivateRoute>
              <PostJob />
            </PrivateRoute>
          }
        />
        <Route
          path="/support"
          element={
              <Support />
          }
        />

        <Route 
          path="/user/profile/:userId" 
          element={
            <PrivateRoute>
              <UserProfile /> 
            </PrivateRoute>
          }
        />
      </Routes>
    </>
  );
}

export default Routers;
